import { Injectable } from '@angular/core';
import { CookieStorageService } from './cookie-storage.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  userKey: string = 'user';
  tokenKey: string = 'authToken';
  username: string = 'admin';
  password: string = 'Parol2020';

  constructor(
    private cookieStorageService: CookieStorageService,
    private router: Router,
  ) {}

  isAuthenticated(): boolean {
    return this.cookieStorageService.hasKey(this.userKey);
  }

  setToken(token) {
    return this.cookieStorageService.setKeyValue(this.tokenKey, token);
  }

  getToken() {
    return this.cookieStorageService.getKeyObjectValue(this.tokenKey);
  }

  deleteToken() {
    return this.cookieStorageService.deleteKey(this.tokenKey);
  }

  login(login, password) {
    if (login !== this.username || password !== this.password) {
      return false;
    }
    this.cookieStorageService.setKeyValue(this.userKey, 'true');
    this.router.navigate(['/admin']);
    return true;
  }

  logout() {
    this.cookieStorageService.deleteKey(this.userKey);
    this.router.navigate(['/login']);
  }

}
