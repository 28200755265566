import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root',
})
export class CookieStorageService {

  private path = '/';
  private domain = 'localhost';
  private expires: Date = new Date();
  constructor(
    private storage: CookieService,
  ) {
    this.domain = window.location.hostname;
    this.expires.setHours(this.expires.getHours() + 24);
  }
  setDomain(domain: string) {
    this.domain = domain;
  }
  /**
   * NB: We could add additional security that parses for
   * insecure content like script references
   *
   * @param {string} keyName
   * @param {string} keyValue
   * @returns {boolean}
   * @memberof CookieStorageService
   */
  setKeyValue(keyName: string, keyValue: string, expires?): boolean {
    if (keyValue == null) {
      return false;
    }
    this.storage.set(keyName, keyValue, expires || this.expires , this.path, this.domain, true, 'None');
      // Second check for ensuring cookie is writeable
    return this.storage.get(keyName) === keyValue;
  }

  /**
   * The Object value wrapper for setting client storage dara
   *
   * @private
   * @param {string} keyName
   * @param {*} keyValue
   * @returns boolean - true if stored otherwise false
   * @memberof ClientStorageService
   */
  setObjectValue(keyName: string, keyValue): boolean {
    // note non-strict comparison allows for null or undefined
    if (keyValue == null) {
      return false;
    }
    return this.setKeyValue(keyName, JSON.stringify(keyValue));
  }

  /**
   * Gets the client stored key valye
   *
   * @private
   * @param {string} keyName
   * @returns {string} null if not found
   * @memberof ClientStorageService
   */
  getKeyValue(keyName: string): string {
    if (this.hasKey(keyName)) {
      return this.storage.get(keyName);
    }
    return null;
  }

  /**
   * Returns the JSON parsed object
   * May be worth consiudering localised deserialisers
   * for each model
   * @private
   * @param {string} keyName
   * @returns {*} null object if not found
   * @memberof ClientStorageService
   */
  getKeyObjectValue(keyName: string) {
    const returnString = this.getKeyValue(keyName);
    try {
      return JSON.parse(returnString);
    } catch (e) {
      return null;
    }
  }
  /**
   * Deletes a key
   * @private
   * @param {string} keyName
   * @returns
   * @memberof ClientStorageService
   */
  deleteKey(keyName: string) {
    if (this.hasKey(keyName)) {
      this.storage.delete(keyName, this.path, this.domain);
    }
    return;
  }
  clearData() {
    this.storage.deleteAll(this.path, this.domain);
    // this.storage.deleteAll('/', '/');
    // this.storage.deleteAll();
  }
  hasKey(keyName: string) {
    return this.storage.check(keyName);
  }

}
